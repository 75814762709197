<template>
	<Popover v-slot="{ open }" class="relative">
		<PopoverButton
			class="hidden md:inline-block items-center justify-center h-8 w-8 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 active:outline-none active:ring-2 active:ring-offset-2 active:ring-primary-500 relative"
			:class="{
				'outline-none': open,
				'ring-2': open,
				'ring-offset-2': open,
				'ring-primary-500': open,
			}"
		>
			<span class="sr-only">View notifications</span>
			<FontAwesomeIcon :icon="faBell" aria-hidden="true" class="h-6 w-6" />
		</PopoverButton>
		<Button
			classes="md:hidden items-center justify-center h-8 w-8 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 inline-block relative"
			@click="routeToNotifications"
		>
			<span class="sr-only">Open notifications</span>
			<FontAwesomeIcon :icon="faBell" aria-hidden="true" class="h-6 w-6" />
		</Button>

		<Transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
		>
			<PopoverPanel class="absolute z-100 transform -translate-x-5/6 mt-3 px-2 w-screen max-w-md sm:px-0">
				<div class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div class="relative grid gap-1 bg-gray-100 p-1">
						<h1 class="text-2xl font-bold ml-4 mt-2">Notifications</h1>
						<div class="flex flex-row justify-between items-center mt-2">
							<div class="flex flex-row items-center justify-start">
								<Button
									class="inline-flex w-full justify-center items-center rounded-md px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
									:class="{
										'bg-white': showUnreadOnly,
										'bg-primary-500': !showUnreadOnly,
										'text-white': !showUnreadOnly,
									}"
									@click="showUnreadOnly = false"
									>All</Button
								>
								<Button
									class="inline-flex w-full justify-center items-center rounded-md px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
									:class="{
										'bg-white': !showUnreadOnly,
										'bg-primary-500': showUnreadOnly,
										'text-white': showUnreadOnly,
									}"
									@click="showUnreadOnly = true"
									>Unread</Button
								>
							</div>
							<div class="flex flex-row items-center justify-start">
								<Button classes="inline-block items-center justify-center h-8 w-8 bg-white p-1 rounded-full text-black hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-primary-500 relative mr-2">
									<span class="sr-only">View notification options</span>
									<FontAwesomeIcon :icon="faEllipsis" aria-hidden="true" class="h-6 w-6" />
								</Button>
							</div>
						</div>
						<hr class="mt-2 mb-0.5" />
						<div
							v-for="globalActionItem in globalActionItems"
							:key="globalActionItem.name"
							class="p-3 flex items-start rounded-lg hover:bg-gray-50 bg-white select-none"
							@click="openGlobalAction(globalActionItem.toggle, globalActionItem.type)"
						>
							<FontAwesomeIcon :icon="globalActionItem.icon" aria-hidden="true" class="flex-shrink-0 h-6 w-6 text-primary-600 mt-2" />
							<div class="ml-4">
								<p class="text-base font-medium text-gray-900">
									{{ globalActionItem.name }}
								</p>
								<p class="mt-1 text-sm text-gray-500">
									{{ globalActionItem.description }}
								</p>
							</div>
							<div class="flex-1 h-full">
								<div class="flex h-full w-full justify-end items-center">
									<FontAwesomeIcon :icon="faChevronRight" aria-hidden="true" class="flex-shrink-0 h-6 w-6 text-primary-600" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopoverPanel>
		</Transition>
	</Popover>
</template>

<script setup lang="ts">
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faBell, faEllipsis, IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import { faBallotCheck, faChevronRight, faDiagramProject, faTableLayout, faWrench } from '@fortawesome/pro-regular-svg-icons';
	import { Ref, ref } from 'vue';
	import Button from '@components/Button.vue';
	import { useRouter } from 'vue-router';

	const router = useRouter();

	export interface GlobalActionItem {
		name: string;
		type?: string;
		description: string;
		toggle: Ref<boolean>;
		icon: IconDefinition;
	}

	const showUnreadOnly = ref<boolean>(false);

	const createNewActivity = ref(false);
	const createNewActivityType = ref();
	const createNewWorkflow = ref(false);
	const createNewForm = ref(false);
	const createNewTemplate = ref(false);

	const routeToNotifications = () => {
		console.dir('routing to notifications');
		router.push({
			name: 'notifications',
		});
	};

	const openGlobalAction = (toggleVariable: Ref<boolean>, type: string | undefined) => {
		if (type) {
			createNewActivityType.value = type;
		}
		toggleVariable.value = true;
	};

	const globalActionItems: GlobalActionItem[] = [
		{
			name: 'New ticket',
			type: 'ticket',
			description: 'Create a new ticket.',
			toggle: createNewActivity,
			icon: faBallotCheck,
		},
		{
			name: 'New workflow',
			description: 'Create a new workflow.',
			toggle: createNewWorkflow,
			icon: faDiagramProject,
		},
		{
			name: 'New form',
			description: 'Create a new form.',
			toggle: createNewForm,
			icon: faTableLayout,
		},
		{
			name: 'New template',
			description: 'Create a new activity template.',
			toggle: createNewTemplate,
			icon: faWrench,
		},
	];
</script>
