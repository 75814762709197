<template>
	<TransitionRoot :show="baseStore.globalSearchOpen" as="template" appear @after-leave="query = ''">
		<Dialog as="div" class="relative z-50" @close="baseStore.globalSearchOpen = false">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20 md:pt-44">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
					<DialogPanel class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
						<Combobox @update:model-value="onSelect">
							<div class="relative">
								<FontAwesomeIcon :icon="faMagnifyingGlass" aria-hidden="true" class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" />
								<ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm focus-visible:outline-none" placeholder="Search..." @change="query = $event.target.value" />
							</div>

							<ComboboxOptions v-if="filteredPeople.length > 0" static class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
								<ComboboxOption v-for="person in filteredPeople" :key="person.id" v-slot="{ active }" :value="person" as="template">
									<li :class="['cursor-default select-none px-4 py-2', active && 'bg-primary-600 text-white']">
										{{ person.name }}
									</li>
								</ComboboxOption>
							</ComboboxOptions>

							<p v-if="query !== '' && filteredPeople.length === 0" class="p-4 text-sm text-gray-500">No people found.</p>
						</Combobox>
					</DialogPanel>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
	<button
		class="items-center justify-center h-8 w-8 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 inline-block relative"
		@click="baseStore.globalSearchOpen = true"
	>
		<span class="sr-only">Search</span>
		<FontAwesomeIcon :icon="faMagnifyingGlass" aria-hidden="true" class="h-6 w-6" />
	</button>
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue';
	import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { useBaseStore } from '@store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

	const baseStore = useBaseStore();
	const people = [
		{ id: 1, name: 'Leslie Alexander', url: '#' },
		// More people...
	];

	const query = ref('');
	const filteredPeople = computed(() =>
		query.value === ''
			? []
			: people.filter((person) => {
					return person.name.toLowerCase().includes(query.value.toLowerCase());
				}),
	);

	const onSelect = (url: string) => {
		window.location.href = url;
	};
</script>

<style scoped></style>
