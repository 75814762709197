<template>
	<Button classes="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600" @click="mobileMainMenuOpen = true">
		<span class="sr-only">Open main menu</span>
		<FontAwesomeIcon :icon="faBars" aria-hidden="true" class="block h-6 w-6" />
	</Button>
	<TransitionRoot :show="mobileMainMenuOpen" as="template">
		<Dialog :open="mobileMainMenuOpen" as="div" class="fixed inset-0 z-40 md:hidden" static @close="mobileMainMenuOpen = false">
			<TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
				<DialogOverlay class="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
			</TransitionChild>
			<TransitionChild
				as="template"
				enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
				enter-from="transform-gpu opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
				enter-to="transform-gpu opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
				leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
				leave-from="transform-gpu opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
				leave-to="transform-gpu opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
			>
				<nav aria-label="Global" class="fixed z-40 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg">
					<div class="h-16 flex items-center justify-between px-4 sm:px-6">
						<img alt="Conduction Logo" class="block h-36 w-auto -translate-y-10 pointer-events-none" src="/logo.svg" />
						<Button
							classes="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600"
							@click="mobileMainMenuOpen = false"
						>
							<span class="sr-only">Close main menu</span>
							<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
						</Button>
					</div>
					<div class="max-w-8xl mx-auto py-3 px-2 sm:px-4">
						<NavigationMenuItem
							v-for="item in props.items"
							:key="item.name"
							:break-class="'my-2 border-t-1'"
							:icon-class="'h-6 w-6 mr-4'"
							:icon-not-selected-class="''"
							:icon-selected-class="''"
							:item="item"
							:item-class="'block rounded-md py-1 pb-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100 flex items-end'"
							:not-selected-class="''"
							:selected-class="''"
						/>
					</div>
					<div class="absolute bottom-0 flex p-4 pl-2 pb-4 pr-16 bg-primary w-full">
						<Button classes="shrink-0 group block rounded-md py-1 px-2 text-primary-100 hover:bg-primary hover:text-white w-full" @click="routeToSettings">
							<div class="flex flex-row items-center justify-start">
								<div class="w-14 h-14 min-h-14 min-w-14 max-h-14 max-w-14">
									<UserAvatar :size="14" />
								</div>

								<div class="ml-4 flex flex-col justify-start text-left">
									<span class="flex flex-row font-medium text-lg text-white text-nowrap">{{ userStore.getUserFullName }}</span>
									<span class="flex flex-row text-sm font-light text-primary-100 group-hover:text-white text-nowrap">Account settings</span>
								</div>
							</div>
						</Button>
						<div class="absolute right-16 bottom-12">
							<Button classes="mx-auto my-auto w-14 h-14 text-white absolute inset-0 z-10 flex justify-center items-center" @click="authStore.logout()">
								<span class="sr-only">Logout</span>
								<FontAwesomeIcon :icon="faArrowRightFromBracket" class="h-1/2 w-1/2" aria-hidden="true" />
							</Button>
						</div>
					</div>
				</nav>
			</TransitionChild>
		</Dialog>
	</TransitionRoot>
</template>

<script setup lang="ts">
	import { defineProps, ref } from 'vue';
	import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faBars, faXmark } from '@fortawesome/pro-light-svg-icons';
	import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
	import Button from '@components/Button.vue';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import NavigationMenuItem from '@components/NavigationMenuItem.vue';
	import { useUserStore } from '@modules/user/store';
	import { useAuthStore } from '@modules/auth/store';
	import { NavigationMenuItemType } from '@/types/layout';
	import { useRouter } from 'vue-router';

	interface Props {
		items: NavigationMenuItemType[];
	}
	const props = defineProps<Props>();

	const userStore = useUserStore();
	const authStore = useAuthStore();
	const mobileMainMenuOpen = ref(false);
	const router = useRouter();
	const routeToSettings = () => {
		mobileMainMenuOpen.value = false;
		router.push({
			name: 'settings',
		});
	};
</script>

<style scoped></style>
