<template>
	<TransitionRoot as="template" :show="props.open">
		<Dialog as="div" class="relative z-10" @close="emit('close')">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl flex flex-col justify-between">
							<div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<Button classes="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none" @click="emit('close')">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</Button>
							</div>
							<div class="px-4 pt-4 sm:pt-8 pb-4 sm:flex sm:flex-grow sm:items-start sm:w-full">
								<div class="sm:mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left sm:flex sm:flex-col sm:w-full">
									<DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Create a new {{ props.type }}!</DialogTitle>
									<div v-if="!loading" class="sm:mt-2 flex flex-col flex-grow justify-center">
										<div v-if="builderStore.templates.length === 0">
											<h1>you need to setup a template before you can create this.</h1>
										</div>
										<FormKit v-else id="createNewActivity" v-model="formData" :value="formData" type="form" :actions="false" class="flex w-full" @submit="submitCreateNewActivity">
											<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
												<FormKitSchema :schema="createActivitySchema()" />
											</div>
										</FormKit>
									</div>
									<Loading v-else />
								</div>
							</div>
							<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:space-x-4 sm:justify-end sm:px-8">
								<Button :disabled="submitting" :label="'Cancel'" color="white" @click="emit('close')" />
								<Button :disabled="submitting" :loading="submitting" :label="'Create ' + props.type" color="primary" @click="triggerActivitySubmit" />
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts" setup>
	import { defineProps, onBeforeMount, ref, watch, withDefaults } from 'vue';
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { setErrors, submitForm } from '@formkit/vue';
	import { useRouter } from 'vue-router';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { useBuilderStore } from '@modules/builder/store';
	import { useActivityStore } from '@modules/activities/store';
	import { CreateActivity } from '@/types/activity';
	import Loading from '@components/Loading.vue';
	import { CardOption } from '@/types/form';
	import Button from '@components/Button.vue';

	interface Props {
		open: boolean;
		type: string;
	}

	const emit = defineEmits<{
		(e: 'close'): void;
	}>();
	const props = withDefaults(defineProps<Props>(), {
		open: false,
		type: 'ticket',
	});

	const activityStore = useActivityStore();
	const builderStore = useBuilderStore();
	const router = useRouter();

	const loading = ref(true);
	const submitting = ref(false);

	const formData = ref<CreateActivity>({
		template_id: '',
	});

	const triggerActivitySubmit = () => {
		submitForm('createNewActivity');
	};

	watch(
		() => builderStore.loading,
		(newValue) => {
			if (!newValue && loading.value) {
				loading.value = false;
			}
		},
	);

	onBeforeMount(() => {
		builderStore.getTemplates();
	});

	const submitCreateNewActivity = (formData: CreateActivity) => {
		submitting.value = true;
		activityStore
			.createNewActivity(formData)
			.then((activity_id: string) => {
				router.push({
					name: 'activity.details',
					params: {
						activity_id: activity_id,
						activity_type: props.type,
					},
				});
			})
			.catch((exception) => {
				setErrors('createNewActivity', [exception.message]);
				submitting.value = false;
			});
	};

	const getTemplateTypeOptions = (): CardOption[] => {
		const templateTypes = [];
		for (const templateType of builderStore.templates) {
			templateTypes.push({
				label: templateType.name,
				description: templateType.description,
				value: templateType.id,
			});
		}
		return templateTypes;
	};

	const createActivitySchema = () => {
		return [
			{
				$formkit: 'stackedCardGroup',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
				id: 'template_id',
				name: 'template_id',
				label: 'Select the type of ' + props.type + ' you are creating',
				cardOptions: getTemplateTypeOptions(),
			},
		] as FormKitSchemaNode[];
	};
</script>
