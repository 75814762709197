<template>
	<TransitionRoot as="template" :show="props.open">
		<Dialog as="div" class="relative z-10" @close="emit('close')">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl flex flex-col justify-between">
							<div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<Button classes="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none" @click="emit('close')">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</Button>
							</div>
							<div class="px-4 pt-4 sm:pt-8 pb-4 sm:flex sm:flex-grow sm:items-start sm:w-full">
								<div class="text-center sm:mt-0 sm:mx-4 sm:text-left sm:flex sm:flex-col sm:w-full">
									<DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Create a new template!</DialogTitle>
									<div class="sm:mt-2 flex flex-col flex-grow justify-center">
										<FormKit id="createNewTemplate" v-model="formData" :value="formData" type="form" :actions="false" class="flex w-full" @submit="submitCreateNewTemplate">
											<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
												<FormKitSchema :schema="createTemplateSchema" />
											</div>
										</FormKit>
									</div>
								</div>
							</div>
							<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:space-x-4 sm:justify-end sm:px-8">
								<Button :disabled="submitting" :label="'Cancel'" color="white" @click="emit('close')" />
								<Button :disabled="submitting" :loading="submitting" label="Create template" color="primary" @click="triggerTemplateSubmit" />
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts" setup>
	import { defineProps, ref, withDefaults } from 'vue';
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { setErrors, submitForm } from '@formkit/vue';
	import { useRouter } from 'vue-router';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { useBuilderStore } from '@modules/builder/store';
	import { CreateTemplateType, TemplateType, TemplateTypeLabel } from '@/types/activityTemplate';
	import { CardOption } from '@/types/form';
	import Button from '@components/Button.vue';

	interface Props {
		open: boolean;
	}

	const emit = defineEmits<{
		(e: 'close'): void;
	}>();
	const props = withDefaults(defineProps<Props>(), {
		open: false,
	});

	const builderStore = useBuilderStore();
	const router = useRouter();

	const formData = ref<CreateTemplateType>({
		name: '',
		description: '',
		type: undefined,
	});

	const submitting = ref(false);

	const triggerTemplateSubmit = () => {
		submitForm('createNewTemplate');
	};

	const submitCreateNewTemplate = (formData: CreateTemplateType) => {
		submitting.value = true;
		builderStore
			.createNewTemplate(formData)
			.then((template_id: string) => {
				router.push({
					name: 'builder.settings',
					params: {
						template_id: template_id,
					},
				});
			})
			.catch((exception) => {
				setErrors('createNewTemplate', [exception.message]);
				submitting.value = false;
			});
	};

	const createTemplateSchema: FormKitSchemaNode[] = [
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true },
			validation: [['required'], ['length:0,255']],
			id: 'name',
			name: 'name',
			label: 'Template name',
			placeholder: 'Enter the name of the template being created',
		},
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			id: 'description',
			name: 'description',
			label: 'Template description',
			placeholder: 'Enter the description of the template being created',
		},
		{
			$formkit: 'cardGroup',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			validation: [['required']],
			id: 'type',
			name: 'type',
			label: 'Activity type',
			simple: true,
			cardOptions: [
				{
					label: TemplateTypeLabel[TemplateType.TICKET],
					value: TemplateType.TICKET,
				},
				{
					label: TemplateTypeLabel[TemplateType.TASK],
					value: TemplateType.TASK,
				},
				{
					label: TemplateTypeLabel[TemplateType.PROJECT],
					value: TemplateType.PROJECT,
				},
				{
					label: TemplateTypeLabel[TemplateType.JOB],
					value: TemplateType.JOB,
				},
				{
					label: TemplateTypeLabel[TemplateType.ACTIVITY],
					value: TemplateType.ACTIVITY,
				},
				{
					label: TemplateTypeLabel[TemplateType.WORK_ORDER],
					value: TemplateType.WORK_ORDER,
				},
			] as CardOption[],
		},
	];
</script>
