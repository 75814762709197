<template>
	<nav aria-label="Main menu" class="hidden md:flex md:shrink-0 overflow-y-hidden">
		<div class="flex flex-col w-24 overflow-y-hidden">
			<div class="flex flex-col h-0 flex-1 overflow-y-hidden bg-black">
				<!-- Main menu top section -->
				<div class="flex-1 flex flex-col items-center overflow-y-hidden">
					<div class="flex flex-col w-full px-2 space-y-1 mt-1 justify-center items-center overflow-y-hidden">
						<NavigationMenuItem
							v-for="item in props.items"
							:key="item.name"
							:break-class="'text-white w-[85%]'"
							:icon-class="'h-6.5 w-6.5'"
							:icon-not-selected-class="'text-primary-400 group-hover:text-white'"
							:icon-selected-class="'text-white'"
							:item="item"
							:item-class="'group min-w-[80%] p-1 rounded-md flex flex-col items-center text-xs font-medium'"
							:not-selected-class="'text-primary-100 hover:bg-primary-800 hover:text-white'"
							:selected-class="'bg-primary-900 text-white'"
							:heading-class="'flex justify-center items-center py-px'"
						/>
					</div>
				</div>
				<!-- Main menu bottom section -->
				<div class="shrink-0 flex pt-4 pb-1 items-center">
					<RouterLink :to="{ name: 'settings' }" class="shrink-0 w-full items-center group">
						<div class="h-14 w-14 relative group mx-auto">
							<div :style="{ 'background-image': 'url(/logo.svg)' }" class="absolute inset-0 bg-cover rounded-full bg-center z-0 group-hover:opacity-50"></div>
							<FontAwesomeIcon :icon="faGear" aria-hidden="true" class="mx-auto my-auto w-9 h-9 opacity-0 group-hover:opacity-100 group-hover:text-white absolute inset-0 z-10 flex justify-center items-center text-primary-500" />
						</div>
					</RouterLink>
				</div>
				<div class="shrink-0 flex items-center text-white text-center w-full text-xxs">
					<span class="mx-auto">{{ baseStore.version }}</span>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup lang="ts">
	import { defineProps } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faGear } from '@fortawesome/pro-regular-svg-icons';
	import NavigationMenuItem from '@components/NavigationMenuItem.vue';
	import { NavigationMenuItemType } from '@/types/layout';
	import { useBaseStore } from '@store';

	interface Props {
		items: NavigationMenuItemType[];
	}

	const baseStore = useBaseStore();

	const props = defineProps<Props>();
</script>

<style scoped></style>
