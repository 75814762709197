<template>
	<Popover class="relative">
		<PopoverButton class="flex items-center justify-center p-1 h-8 w-8 text-gray-400 group bg-white rounded-full text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
			<span class="sr-only">Global Actions</span>
			<FontAwesomeIcon :icon="faPlusLarge" aria-hidden="true" class="h-5 w-5" />
		</PopoverButton>

		<Transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
		>
			<PopoverPanel class="absolute z-100 left-1/2 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-xs sm:px-0">
				<div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div class="relative grid gap-1 bg-gray-100 p-1">
						<div
							v-for="globalActionItem in globalActionItems"
							:key="globalActionItem.name"
							class="p-3 flex items-start rounded-lg hover:bg-gray-50 bg-white select-none"
							@click="openGlobalAction(globalActionItem.toggle, globalActionItem.type)"
						>
							<FontAwesomeIcon :icon="globalActionItem.icon" aria-hidden="true" class="flex-shrink-0 h-6 w-6 text-primary-600 mt-2" />
							<div class="ml-4">
								<p class="text-base font-medium text-gray-900">
									{{ globalActionItem.name }}
								</p>
								<p class="mt-1 text-sm text-gray-500">
									{{ globalActionItem.description }}
								</p>
							</div>
							<div class="flex-1 h-full">
								<div class="flex h-full w-full justify-end items-center">
									<FontAwesomeIcon :icon="faChevronRight" aria-hidden="true" class="flex-shrink-0 h-6 w-6 text-primary-600" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopoverPanel>
		</Transition>
	</Popover>

	<CreateActivity v-if="createNewActivity" :open="createNewActivity" :type="plur(createNewActivityType, 1)" @close="createNewActivity = false" />
	<CreateTemplate v-if="createNewTemplate" :open="createNewTemplate" @close="createNewTemplate = false" />
	<CreateForm v-if="createNewForm" :open="createNewForm" @close="createNewForm = false" />
	<CreateWorkflow v-if="createNewWorkflow" :open="createNewWorkflow" @close="createNewWorkflow = false" />
</template>

<script setup lang="ts">
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPlusLarge, IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import { faBallotCheck, faChevronRight, faDiagramProject, faTableLayout, faWrench } from '@fortawesome/pro-regular-svg-icons';
	import CreateTemplate from '@modules/builder/components/CreateTemplate.vue';
	import { Ref, ref } from 'vue';
	import CreateForm from '@modules/form/components/CreateForm.vue';
	import CreateWorkflow from '@modules/workflow/components/CreateWorkflow.vue';
	import plur from '@utils/usePlural';
	import CreateActivity from '@modules/activities/components/CreateActivity.vue';

	export interface GlobalActionItem {
		name: string;
		type?: string;
		description: string;
		toggle: Ref<boolean>;
		icon: IconDefinition;
	}

	const createNewActivity = ref(false);
	const createNewActivityType = ref();
	const createNewWorkflow = ref(false);
	const createNewForm = ref(false);
	const createNewTemplate = ref(false);

	const openGlobalAction = (toggleVariable: Ref<boolean>, type: string | undefined) => {
		if (type) {
			createNewActivityType.value = type;
		}
		toggleVariable.value = true;
	};

	const globalActionItems: GlobalActionItem[] = [
		{
			name: 'New ticket',
			type: 'ticket',
			description: 'Create a new ticket.',
			toggle: createNewActivity,
			icon: faBallotCheck,
		},
		{
			name: 'New workflow',
			description: 'Create a new workflow.',
			toggle: createNewWorkflow,
			icon: faDiagramProject,
		},
		{
			name: 'New form',
			description: 'Create a new form.',
			toggle: createNewForm,
			icon: faTableLayout,
		},
		{
			name: 'New template',
			description: 'Create a new activity template.',
			toggle: createNewTemplate,
			icon: faWrench,
		},
	];
</script>
