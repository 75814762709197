<template>
	<RouterLink
		v-if="item.category === 'User' || item.category === 'Builder' || item.category === 'Admin'"
		:key="item.name"
		:class="[route.matched.some(({ name }) => name === item.to?.name) && Object.entries(route.params ?? {}).toString() === Object.entries(item.to?.params ?? {}).toString() ? selectedClass : notSelectedClass, itemClass]"
		:to="item.to"
	>
		<FontAwesomeIcon
			:icon="item.icon"
			:class="[route.matched.some(({ name }) => name === item.to?.name) && Object.entries(route.params ?? {}).toString() === Object.entries(item.to?.params ?? {}).toString() ? iconSelectedClass : iconNotSelectedClass, iconClass]"
			aria-hidden="true"
		/>
		<span :class="headingClass">{{ item.name }}</span>
	</RouterLink>
	<hr v-else-if="item.category === 'Break'" :class="breakClass" />
</template>

<script setup lang="ts">
	import { defineProps, withDefaults } from 'vue';
	import { NavigationMenuItemType } from '@/types/layout';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { useRoute } from 'vue-router';

	const route = useRoute();

	interface Props {
		item: NavigationMenuItemType;
		hoverClass?: string;
		selectedClass?: string;
		notSelectedClass?: string;
		itemClass?: string;
		iconSelectedClass?: string;
		iconNotSelectedClass?: string;
		iconClass?: string;
		headingClass?: string;
		breakClass?: string;
		ariaCurrent?: string;
	}

	withDefaults(defineProps<Props>(), {
		hoverClass: '',
		selectedClass: 'bg-primary-800 text-white',
		notSelectedClass: 'text-primary-100 hover:bg-primary-800 hover:text-white',
		itemClass: 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium my-1',
		iconSelectedClass: 'text-white',
		iconNotSelectedClass: 'text-primary-300 group-hover:text-white',
		iconClass: 'h-6 w-6',
		breakClass: 'mt-2',
		headingClass: 'mt-2',
		ariaCurrent: undefined,
	});
</script>
