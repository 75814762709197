<template>
	<div class="h-screen overflow-hidden bg-gray-100 flex flex-col">
		<!-- LAYOUT HEADER -->
		<header class="shrink-0 relative h-16 bg-white flex flex-row items-center justify-between">
			<!-- Logo area -->
			<div class="justify-self-start md:static md:shrink-0">
				<div class="flex items-center justify-center h-16 w-16 bg-black md:w-24 text-xl text-white">
					<div class="mx-auto md:-translate-y-8">
						<img
							alt="Conduction Logo"
							class="block w-12 md:w-22"
							src="/logo.svg"
							:class="{
								'animate-spin': baseStore.loading,
							}"
						/>
					</div>
				</div>
			</div>

			<!-- Main heading / Second level nav -->
			<div class="flex w-[calc(100vw-4rem-80px)] md:absolute md:inset-y-0 xl:pl-24 md:w-full justify-center h-16 items-center overflow-x-hidden">
				<slot name="navigation"></slot>
			</div>

			<!-- Mobile Navigation / Desktop functions area -->
			<div class="flex items-center justify-end justify-self-end space-x-2 mr-2 w-[80px] md:w-auto">
				<!-- Global actions button - DESKTOP ONLY -->
				<span class="hidden md:inline-flex items-center justify-center">
					<GlobalActions />
				</span>
				<!-- Global search button - DESKTOP ONLY -->
				<span class="hidden md:inline-flex items-center justify-center">
					<GlobalSearch />
				</span>
				<!-- Notification button -->
				<span class="inline-flex items-center justify-center">
					<GlobalNotifications />
				</span>
				<!-- Account menu button - DESKTOP ONLY -->
				<span class="hidden md:inline-flex items-center justify-center">
					<AccountMenu />
				</span>
				<!-- Mobile main menu button - MOBILE ONLY -->
				<nav class="flex flex-row items-center md:hidden">
					<MobileMainMenu :items="navigation" />
				</nav>
			</div>
		</header>
		<!-- END LAYOUT HEADER -->

		<!-- LAYOUT CONTENT SECTION (Everything below the h16 header!) -->
		<div class="min-h-0 flex-1 flex overflow-hidden">
			<!-- Main navigational menu sidebar -->
			<DesktopMainMenu :items="navigation" />

			<div class="flex flex-col h-full w-full overflow-hidden border-gray-200">
				<!-- Main page header -->
				<div v-if="$slots.header" class="bg-primary-700 w-full">
					<div
						:class="{
							'lg:w-3/4': secondaryPanelOpen,
							'lg:max-w-3/4': secondaryPanelOpen,
							'lg:min-w-1/4': secondaryPanelOpen,
							'lg:pr-2': secondaryPanelOpen,
							'lg:w-full': !secondaryPanelOpen,
							'lg:max-w-full': !secondaryPanelOpen,
						}"
						:style="{
							width: debouncedSecondaryPanelWidth,
						}"
					>
						<slot name="header"></slot>
					</div>
				</div>
				<!-- Main page content -->
				<main
					class="bg-gray-100 flex-1 overflow-y-auto h-full max-h-full transition-all duration-300 transform"
					:class="{
						'lg:w-3/4': secondaryPanelOpen,
						'lg:max-w-3/4': secondaryPanelOpen,
						'lg:min-w-1/4': secondaryPanelOpen,
						'lg:pr-6': secondaryPanelOpen,
						'lg:w-full': !secondaryPanelOpen,
						'lg:max-w-full': !secondaryPanelOpen,
					}"
					:style="{
						width: debouncedSecondaryPanelWidth,
					}"
				>
					<slot name="primary"></slot>
				</main>
			</div>

			<!-- Secondary Content -->
			<TransitionRoot :show="secondaryPanelOpen" as="template" @after-leave="onSecondaryAfterLeave" @after-enter="onSecondaryAfterEnter">
				<div class="absolute overflow-hidden">
					<div
						class="fixed inset-y-0 right-0 top-16 flex z-40 min-w-full max-w-full sm:max-w-[calc((100%-96px)*0.75)] sm:min-w-1/4 resizable"
						:style="{
							width: currentSecondaryPanel,
						}"
					>
						<TransitionChild
							as="template"
							enter="transform-gpu transition ease-in-out duration-500 sm:duration-300"
							enter-from="translate-x-full"
							enter-to="translate-x-0"
							leave="transform-gpu transition ease-in-out duration-500 sm:duration-300"
							leave-from="translate-x-0"
							leave-to="translate-x-full"
						>
							<aside class="h-full w-full bg-white shadow-3xl z-40 sm:border-t sm:border-l border-gray-200 flex flex-row">
								<div class="h-full w-full flex flex-col justify-center">
									<div class="flex flex-row items-center justify-between pl-4 py-2.5 bg-primary-700">
										<slot name="secondary_title_section">
											<h2 class="text-lg font-medium text-white"><slot name="secondary_title_text"></slot></h2>
										</slot>
										<div class="ml-3 flex h-6 items-center pr-2 sm:pr-2">
											<Button classes="h-6 rounded-md bg-white text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2" @click="baseStore.secondaryPanelOpen = false">
												<span class="sr-only">Close side panel</span>
												<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
											</Button>
										</div>
									</div>
									<div class="flex flex-col h-full w-full overflow-hidden border-t">
										<slot name="secondary"></slot>
									</div>
								</div>
							</aside>
						</TransitionChild>
					</div>
				</div>
			</TransitionRoot>
		</div>
		<!-- END LAYOUT CONTENT SECTION -->
		<GlobalAnnouncements></GlobalAnnouncements>
	</div>
</template>

<script setup lang="ts">
	import { computed, provide, ref, useSlots, watch } from 'vue';
	import { TransitionChild, TransitionRoot } from '@headlessui/vue';
	import GlobalSearch from '@components/GlobalSearch.vue';
	import { NavigationMenuItemType } from '@/types/layout';
	import { useBaseStore } from '@store';
	import GlobalAnnouncements from '@components/GlobalAnnouncements.vue';
	import GlobalActions from '@components/GlobalActions.vue';
	import AccountMenu from '@components/AccountMenu.vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { faBallotCheck, faCode, faDiagramProject, faHouseUser, faTableLayout, faWrench } from '@fortawesome/pro-regular-svg-icons';
	import interact from 'interactjs';
	import Button from '@components/Button.vue';
	import GlobalNotifications from '@components/GlobalNotifications.vue';
	import MobileMainMenu from '@components/MobileMainMenu.vue';
	import DesktopMainMenu from '@components/DesktopMainMenu.vue';

	const onSecondaryAfterEnter = () => {
		baseStore.secondaryPanelHasOpened();
	};
	const onSecondaryAfterLeave = () => {
		baseStore.secondaryPanelHasClosed();
	};

	const navigation: Array<NavigationMenuItemType> = [
		{ name: 'Dashboard', to: { name: 'dashboard' }, icon: faHouseUser, category: 'User' },
		{ name: 'Break', category: 'Break' },
		{ name: 'Tickets', to: { name: 'activity.index', params: { activity_type: 'ticket' } }, icon: faBallotCheck, category: 'User' },
		/*		{ name: 'Tasks', to: { name: 'activity.index', params: { activity_type: 'task' } }, icon: faListCheck, category: 'User' },
		{ name: 'Projects', to: { name: 'activity.index', params: { activity_type: 'project' } }, icon: faChartGantt, category: 'User' },
		{ name: 'Activities', to: { name: 'activity.index', params: { activity_type: 'activity' } }, icon: faWavePulse, category: 'User' },*/
		{ name: 'Break', category: 'Break' },
		/*		{ name: 'Assets', to: { name: 'general settings' }, icon: faGears, category: 'User' },
		{ name: 'Sites', to: { name: 'general settings' }, icon: faGears, category: 'User' },
		{ name: 'Services', to: { name: 'general settings' }, icon: faGears, category: 'User' },
		{ name: 'Break', category: 'Break' },*/
		{ name: 'Workflows', to: { name: 'workflow.index' }, icon: faDiagramProject, category: 'Builder' },
		{ name: 'Forms', to: { name: 'form.index' }, icon: faTableLayout, category: 'Builder' },
		{ name: 'Builder', to: { name: 'builder.index' }, icon: faWrench, category: 'Builder' },
		{ name: 'Integrations', to: { name: 'integration.index' }, icon: faCode, category: 'Builder' },
	];

	const slots = useSlots();
	const baseStore = useBaseStore();
	const debouncedSecondaryPanel = ref(baseStore.isMobile || baseStore.isTablet ? baseStore.windowWidth + 'px' : '40%');
	const currentSecondaryPanel = ref(baseStore.isMobile || baseStore.isTablet ? baseStore.windowWidth + 'px' : '40%');
	const storedSecondaryPanel = ref();

	const secondaryPanelOpen = computed(() => {
		if (typeof slots.secondary === 'undefined') {
			return false;
		}
		return baseStore.secondaryPanelOpen;
	});

	const debouncedSecondaryPanelWidth = computed(() => {
		if (secondaryPanelOpen.value) {
			if (baseStore.isMobile || baseStore.isTablet) {
				return baseStore.windowWidth + 'px';
			}
			let widthToUse = debouncedSecondaryPanel.value;
			if (!debouncedSecondaryPanel.value.toString().endsWith('%') && parseInt(debouncedSecondaryPanel.value.replace('px', '')) / (baseStore.windowWidth - 96) >= 0.75) {
				widthToUse = (baseStore.windowWidth - 96) * 0.75 + 'px';
			}
			return 'calc(' + (baseStore.windowWidth - 96) + 'px - ' + widthToUse + (debouncedSecondaryPanel.value.toString().endsWith('%') ? ' - 10px)' : ' + 25px)');
		}
		return '100%';
	});

	watch(
		() => baseStore.currentBreakpoint,
		() => {
			if (baseStore.isMobile || baseStore.isTablet) {
				storedSecondaryPanel.value = currentSecondaryPanel.value;
				currentSecondaryPanel.value = baseStore.windowWidth + 'px';
			}
			if (storedSecondaryPanel.value) {
				currentSecondaryPanel.value = storedSecondaryPanel.value;
				storedSecondaryPanel.value = undefined;
			}
			return '40%';
		},
	);

	interact('.resizable').resizable({
		edges: { top: false, left: true, bottom: false, right: false },
		listeners: {
			move: function (event) {
				if (!baseStore.isMobile && !baseStore.isTablet) {
					let { x, y } = event.target.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					currentSecondaryPanel.value = `${event.rect.width}px`;

					Object.assign(event.target.dataset, { x, y });
				}
			},
			end: function (event) {
				if (!baseStore.isMobile && !baseStore.isTablet) {
					debouncedSecondaryPanel.value = event.rect.width + 'px';
				}
			},
		},
	});

	provide('contentWidth', debouncedSecondaryPanel);
</script>
