const slugify = (stringToSlugify: string): string =>
	stringToSlugify
		.toString()
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '_')
		.replace(/^-+|-+$/g, '');

export default slugify;
