<template>
	<Popover class="relative">
		<PopoverButton class="flex items-center justify-center h-10 w-10 text-gray-400 group bg-white rounded-full text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
			<span class="sr-only">Account settings</span>
			<UserAvatar :size="10" />
		</PopoverButton>

		<Transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
		>
			<PopoverPanel v-slot="{ close }" class="absolute z-100 left-10 transform -translate-x-full mt-1 px-2 w-screen max-w-sm sm:px-0">
				<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
					<div class="flex flex-row justify-start items-center w-full px-14 py-3">
						<div class="w-16 h-16 min-h-16 min-w-16 max-h-16 max-w-16">
							<UserAvatar :size="16" :classes="'text-2xl'" />
						</div>
						<div class="w-full flex flex-col justify-center items-center">
							<h1 class="font-bold text-xl text-primary">{{ userStore.getUserFullName }}</h1>
							<h2 class="font-semibold text-sm">Front end developer</h2>
							<h2 class="font-semibold text-sm">Duke street studio</h2>
						</div>
					</div>
					<hr />
					<div class="relative grid bg-white gap-8 px-8 py-5">
						<button v-for="item in accountMenuItems" :key="item.name" class="text-left -m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50" @click="doAction(close, item.to, item.onClick)">
							<FontAwesomeIcon :icon="item.icon" aria-hidden="true" class="h-6 w-6 flex-shrink-0 text-primary pt-1" />
							<span class="ml-4">
								<span class="text-base font-medium text-gray-900">{{ item.name }}</span>
								<br />
								<span class="mt-1 text-sm text-gray-500">{{ item.description }}</span>
							</span>
						</button>
					</div>
					<div class="bg-primary-500 py-4 flex space-y-0 sm:space-x-10 px-6 justify-between">
						<div v-for="item in userMenuActions" :key="item.name" class="flow-root">
							<button class="-m-3 flex items-center rounded-md p-3 text-base font-medium text-white transition duration-150 ease-in-out hover:bg-primary-400" @click="doAction(close, item.to)">
								<FontAwesomeIcon :icon="item.icon" aria-hidden="true" class="h-6 w-6 flex-shrink-0 text-white" />
								<span class="ml-3">{{ item.name }}</span>
							</button>
						</div>
					</div>
				</div>
			</PopoverPanel>
		</Transition>
	</Popover>
</template>

<script setup lang="ts">
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
	import { useUserStore } from '@modules/user/store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faArrowRightFromBracket, faBug, faGear, faGift, faHeadset } from '@fortawesome/pro-regular-svg-icons';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import { SentryConfig } from '@/config/sentry';
	import { feedbackIntegration } from '@sentry/vue';
	import { LocationAsRelativeRaw, useRouter } from 'vue-router';
	import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
	import { useBaseStore } from '@store';

	const userStore = useUserStore();
	const router = useRouter();
	const baseStore = useBaseStore();

	const doAction = (close: () => void, to: LocationAsRelativeRaw | undefined, onClick: (() => void) | undefined = undefined) => {
		console.dir('doing action');
		if (onClick) {
			onClick();
		}
		if (to) {
			router.push(to);
		}
		close();
	};

	const accountMenuItems: { onClick: (() => void) | undefined; name: string; icon: IconDefinition; description: string; to: LocationAsRelativeRaw | undefined }[] = [
		{
			name: 'Support',
			description: 'Need some assistance? Not sure where to start? Reach out and let us help you!',
			to: undefined,
			icon: faHeadset,
			onClick: undefined,
		},
		{
			name: "What's new",
			description: "Find out what new features we've added recently!",
			to: undefined,
			icon: faGift,
			onClick: async () => {
				baseStore.globalAnnouncementsOpen = true;
			},
		},
	];
	const userMenuActions: { name: string; icon: IconDefinition; to: LocationAsRelativeRaw | undefined }[] = [
		{ name: 'User settings', to: { name: 'billing settings' }, icon: faGear },
		{ name: 'Logout', to: { name: 'logout' }, icon: faArrowRightFromBracket },
	];

	if (SentryConfig.enable && SentryConfig.allowUserFeedback) {
		const feedback = feedbackIntegration({
			autoInject: true,
			colorScheme: 'light',
			showName: false,
			showEmail: false,
			useSentryUser: {
				id: 'id',
				name: 'name',
				email: 'email',
			},
		});

		accountMenuItems.push({
			name: 'Report a bug!',
			description: 'Found an issue? let us know and we will fix it up!',
			to: undefined,
			icon: faBug,
			onClick: async () => {
				const form = await feedback.createForm({});
				form.appendToDom();
				form.open();
			},
		});
	}
</script>
