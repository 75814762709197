<template>
	<TransitionRoot as="template" :show="props.open">
		<Dialog as="div" class="relative z-10" @close="emit('close')">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl flex flex-col justify-between">
							<div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<Button classes="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none" @click="emit('close')">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</Button>
							</div>
							<div class="px-4 pt-4 sm:pt-8 pb-4 sm:flex sm:flex-grow sm:items-start sm:w-full">
								<div class="text-center sm:mt-0 sm:mx-4 sm:text-left sm:flex sm:flex-col sm:w-full">
									<DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Create a new workflow!</DialogTitle>
									<div class="sm:mt-2 flex flex-col flex-grow justify-center">
										<FormKit id="createNewWorkflow" v-model="workflowData" :value="workflowData" type="form" :actions="false" class="flex w-full" @submit="submitCreateNewWorkflow">
											<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
												<FormKitSchema :schema="createWorkflowSchema" />
											</div>
										</FormKit>
									</div>
								</div>
							</div>
							<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:space-x-4 sm:justify-end sm:px-8">
								<Button :disabled="submitting" :label="'Cancel'" color="white" @click="emit('close')" />
								<Button :disabled="submitting" :loading="submitting" label="Create workflow" color="primary" @click="triggerWorkflowSubmit" />
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts" setup>
	import { defineProps, ref, watch, withDefaults } from 'vue';
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import slugify from '@utils/useSlugify';
	import { setErrors, submitForm } from '@formkit/vue';
	import { useRouter } from 'vue-router';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { CreateWorkflowType } from '@/types/workflow';
	import Button from '@components/Button.vue';

	interface Props {
		open: boolean;
	}

	const emit = defineEmits<{
		(e: 'close'): void;
	}>();
	const props = withDefaults(defineProps<Props>(), {
		open: false,
	});

	const workflowStore = useWorkflowStore();
	const router = useRouter();
	const submitting = ref(false);

	const workflowData = ref<CreateWorkflowType>({
		name: '',
		reference_name: '',
		description: '',
	});

	watch(
		() => workflowData.value.name,
		(newValue) => {
			if (newValue) {
				workflowData.value.reference_name = slugify(newValue);
			}
		},
	);

	const triggerWorkflowSubmit = () => {
		submitForm('createNewWorkflow');
	};

	const submitCreateNewWorkflow = (workflowData: CreateWorkflowType) => {
		submitting.value = true;
		workflowData.reference_name = slugify(workflowData?.name ?? '');
		workflowStore
			.createNewWorkflow(workflowData)
			.then((workflowId: string) => {
				router.push({
					name: 'workflow.show',
					params: {
						workflow_id: workflowId,
					},
				});
			})
			.catch((exception) => {
				console.error(exception);
				setErrors('createNewForm', [exception.message]);
				submitting.value = false;
			});
	};

	const createWorkflowSchema: FormKitSchemaNode[] = [
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true },
			validation: [['required'], ['length:0,255']],
			id: 'name',
			name: 'name',
			label: 'Workflow name',
			placeholder: 'Enter a name for the workflow being created',
		},
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true },
			validation: [['required'], ['length:0,255']],
			id: 'reference_name',
			name: 'reference_name',
			label: 'Workflow reference name',
			readonly: true,
			disabled: true,
		},
		{
			$formkit: 'text',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			id: 'description',
			name: 'description',
			label: 'Workflow description',
			placeholder: 'Enter a description for the workflow being created',
		},
	];
</script>
